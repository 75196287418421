import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../App.css';
import api from './api';

const Entities = () => {
  const [name, setName] = useState('');
  const [thumbnail, setThumbnail] = useState(null);
  const [preview, setPreview] = useState(null);
  const [categoryId, setCategoryId] = useState('');
  const [categories, setCategories] = useState([]);
  const [languages, setLanguages] = useState([]); // State for languages
  const [language, setLanguage] = useState(''); // Selected language
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch categories
    axios.get(api.getCategoriesApi)
      .then(response => {
        if (Array.isArray(response.data)) {
          setCategories(response.data);
        } else {
          setError('Failed to fetch categories');
        }
      })
      .catch(error => {
        console.error('Error fetching categories:', error);
        setError('Error fetching categories');
      });

    // Fetch languages
    axios.get(api.getLanguagesApi) // Ensure api.getLanguagesApi is correctly defined in your 'api' module
      .then(response => {
        if (Array.isArray(response.data)) {
          setLanguages(response.data);
        } else {
          setError('Failed to fetch languages');
        }
      })
      .catch(error => {
        console.error('Error fetching languages:', error);
        setError('Error fetching languages');
      });

  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const producerId = localStorage.getItem('producerId');
    if (!producerId) {
      setError('Producer ID not found. Please log in.');
      return;
    }

    const formData = new FormData();
    formData.append('name', name);
    formData.append('thumbnail', thumbnail);
    formData.append('preview', preview);
    formData.append('categoryId', categoryId);
    formData.append('p_id', producerId);
    formData.append('l_name', language); // Add selected language to the form data

    try {
      const response = await axios.post(api.addEntityApi, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      alert(response.data.message);
      if (response.data.status === 'success') {
        navigate('/manage');
      }
    } catch (error) {
      console.error('Error adding entity:', error);
    }
  };

  return (
    <div className="container">
      <div className="form-container">
        <h2>Add Series</h2>
        {error && <p className="error">{error}</p>}
        <form onSubmit={handleSubmit}>
          <label>
            Series Name
            <input
              type="text"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </label>
          <label>
            Thumbnail Image
            <input
              type="file"
              onChange={(e) => setThumbnail(e.target.files[0])}
              required
            />
          </label>
          <label>
            Trailer Video
            <input
              type="file"
              onChange={(e) => setPreview(e.target.files[0])}
              required
            />
          </label>
          <select value={categoryId} onChange={(e) => setCategoryId(e.target.value)} required>
            <option value="">Select Category</option>
            {categories.map((category) => (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
            ))}
          </select>
          <label>
            Language
            <select value={language} onChange={(e) => setLanguage(e.target.value)} required>
              <option value="">Select Language</option>
              {languages.map((lang) => (
                <option key={lang.id} value={lang.language_name}>
                  {lang.language_name}
                </option>
              ))}
            </select>
          </label>
          <button type="submit">Add Series</button>
        </form>
      </div>
    </div>
  );
};

export default Entities;

