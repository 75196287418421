import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import '../App.css'; // Import the CSS file
import api from './api';

const MoviePlayer = () => {
  const { entityId } = useParams();
  const [videos, setVideos] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetch(`http://localhost/project_work/Vidify-main/netflix/api/getVideosByEntity.php?entityId=${entityId}`)
      .then(response => response.json())
      .then(data => {
        if (data.status === 'success') {
          setVideos(data.data);
        } else {
          console.error('Failed to fetch videos:', data.message);
        }
      })
      .catch(error => console.error('Error fetching videos:', error));
  }, [entityId]);

  // Group videos by season
  const groupedVideos = videos.reduce((acc, video) => {
    acc[video.season] = acc[video.season] || [];
    acc[video.season].push(video);
    return acc;
  }, {});


  return (
    <div className="container">
      <div className="header">

      </div>
      {Object.keys(groupedVideos).map(season => (
        <div key={season} className="season">
          
          <div className="cards-container">
            {groupedVideos[season].map(video => (
              <div className="card" key={video.id}>
                <div className="">
                  <video
                    width="100%"
                    controls
                    src={api.mainFolder+video.filePath}
                    onError={(e) => console.error('Error loading video:', e.target.src)}
                  >
                    Your browser does not support the video tag.
                  </video>
                </div>

              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default MoviePlayer;
