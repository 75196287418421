import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../App.css'; // Import the CSS file
import api from './api'; // Import the API configuration

const debounce = (func, wait) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
};

const Manage = () => {
  const navigate = useNavigate();
  const [series, setSeries] = useState([]);
  const [movies, setMovies] = useState([]);

  useEffect(() => {
    fetchMovies();
    fetchSeries()
  }, []);

  useEffect(() => {
    const handlePopState = () => {
      fetchMovies();
      fetchSeries()
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  const fetchMovies = () => {
    fetch(api.getAllMovieApi )
      .then(response => response.json())
      .then(data => {
        if (data.status === 'success') {
          setMovies(data.data);
        } else {
          console.error(`Failed to fetch `, data.message);
        }
      })
      .catch(error => console.error(`Error fetching `, error));
  };
  const fetchSeries = () => {
    fetch(api.getAllSeriesApi )
      .then(response => response.json())
      .then(data => {
        if (data.status === 'success') {
          setSeries(data.data);
        } else {
          console.error(`Failed to fetch `, data.message);
        }
      })
      .catch(error => console.error(`Error fetching `, error));
  };

  const handleAddSeriesClick = () => {
    navigate('/entities');
  };

  const handleAddMovieClick = () => {
    navigate('/addmovie');
  };

  const handleCardClick = (entityId, isMovie) => {
    navigate(`/entity/${entityId}`, { state: { isMovie } });
  };
  const handleMovieCardClick = (entityId, isMovie) => {
    navigate(`/MoviePlayer/${entityId}`, { state: { isMovie } });
  };


  const handleMouseOver = debounce((event) => {
    event.target.play();
  }, 300);

  const handleMouseOut = debounce((event) => {
    event.target.pause();
  }, 300);

  return (
    <div className="container">
      <div className="form-container">
        <h2>Manage Page</h2>
        <button onClick={handleAddSeriesClick}>Add Series</button>
        <button onClick={handleAddMovieClick}>Add Movie</button>
      </div>
      <div className="entities-grid">
        <h3>All Series</h3>
        <div className="cards-container">
          {series.map(entity => (
            <div className="card" key={entity.id} onClick={() => handleCardClick(entity.id, false)}>
              <div className="thumbnail">
                <img
                  src={api.mainFolder + entity.thumbnail}
                  alt={entity.name}
                  width="100%"
                  
                  onError={(e) => e.target.style.display = 'none'}
                />
                <video
                  width="100%"
                  loop
                  muted
                  onMouseOver={handleMouseOver}
                  onMouseOut={handleMouseOut}
                >
                  <source src={api.mainFolder + entity.preview} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
              <div className="card-content">
                <h4>{entity.name}</h4>
                <p>Category ID: {entity.category_id}</p>
                <p>P ID: {entity.id}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="entities-grid">
        <h3>All Movies</h3>
        <div className="cards-container">
          {movies.map(entity => (
            <div className="card" key={entity.id} onClick={() => handleMovieCardClick(entity.id, true)}>
              <div className="thumbnail">
                <img
                  src={api.mainFolder + entity.thumbnail}
                  alt={entity.name}
                  width="100%"
                  onError={(e) => e.target.style.display = 'none'}
                />
                <video
                  width="100%"
                  loop
                  muted
                  onMouseOver={handleMouseOver}
                  onMouseOut={handleMouseOut}
                >
                  <source src={api.mainFolder + entity.preview} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
              <div className="card-content">
                <h4>{entity.name}</h4>
                <p>Category ID: {entity.category_id}</p>
                <p>P ID: {entity.id}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Manage;
