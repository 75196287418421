import React, { useState, useEffect } from 'react';
import axios from 'axios';
import api from './api';

const AddMovie = () => {
  const [formData, setFormData] = useState({
    name: '',
    thumbnail: null,
    preview: null,
    filePath: null,
    description: '',
    releaseDate: '',
    categoryId: '',
    languages: '',
    language: '',
  });

  const [categories, setCategories] = useState([]);
  const [languages, setLanguages] = useState([]); // State for languages
  const [language, setLanguage] = useState(''); // Selected language
  const [duration, setDuration] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    axios.get(api.getCategoriesApi)
      .then(response => {
        setCategories(response.data);
      })
      .catch(error => {
        console.error('Error fetching categories:', error);
      });
      
    axios.get(api.getLanguagesApi) // Ensure api.getLanguagesApi is correctly defined in your 'api' module
      .then(response => {
        if (Array.isArray(response.data)) {
          setLanguages(response.data);
        } else {
          setError('Failed to fetch languages');
        }
      })
      .catch(error => {
        console.error('Error fetching languages:', error);
        setError('Error fetching languages');
      });
  }, []);

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'file' ? files[0] : value,
    });

    if (type === 'file' && name === 'filePath') {
      const video = document.createElement('video');
      video.preload = 'metadata';
      video.src = URL.createObjectURL(files[0]);

      video.onloadedmetadata = () => {
        window.URL.revokeObjectURL(video.src);
        const videoDuration = Math.floor(video.duration);
        const hours = Math.floor(videoDuration / 3600);
        const minutes = Math.floor((videoDuration % 3600) / 60);
        const seconds = videoDuration % 60;
        setDuration(`${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`);
      };
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const producerId = localStorage.getItem('producerId');
    if (!producerId) {
      setError('Producer ID not found. Please log in.');
      return;
    }

    const formDataToSend = new FormData();
    for (const key in formData) {
      formDataToSend.append(key, formData[key]);
    }
    formDataToSend.append('duration', duration);
    formDataToSend.append('producerId', producerId);
    formDataToSend.append('l_name', language); // Add selected language
     

    axios.post(api.addMovieApi, formDataToSend, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then(response => {
        console.log('Response:', response.data);
        if (response.data.status === 'success') {
          alert('Movie added successfully!');
          setFormData({
            name: '',
            thumbnail: null,
            preview: null,
            filePath: null,
            description: '',
            releaseDate: '',
            categoryId: '',
            languages: '',
            language: '',
          });
          setDuration('');
          setError('');
        } else {
          setError(response.data.message || 'Error adding movie');
        }
      })
      .catch(error => {
        console.error('Error adding movie:', error);
        setError('Error adding movie');
      });
  };

  return (
    <div>
      <h2>Add Movie Page</h2>
      {error && <p>{error}</p>}
      <form onSubmit={handleSubmit}>
        <div>
          <label>Movie Name:</label>
          <input type="text" name="name" value={formData.name} onChange={handleChange} required />
        </div>
        <div>
          <label>Thumbnail:</label>
          <input type="file" name="thumbnail" onChange={handleChange} required />
        </div>
        <div>
          <label>Preview:</label>
          <input type="file" name="preview" onChange={handleChange} required />
        </div>
        <div>
          <label>Video File Path:</label>
          <input type="file" name="filePath" onChange={handleChange} required />
        </div>
        <div>
          <label>Description:</label>
          <textarea name="description" value={formData.description} onChange={handleChange} required></textarea>
        </div>
        <div>
          <label>Release Date:</label>
          <input type="date" name="releaseDate" value={formData.releaseDate} onChange={handleChange} required />
        </div>
        <div>
          <label>Category:</label>
          <select name="categoryId" value={formData.categoryId} onChange={handleChange} required>
            <option value="">Select Category</option>
            {categories.map(category => (
              <option key={category.id} value={category.id}>{category.name}</option>
            ))}
          </select>
        </div>
        <div>
          <label>Language:</label>
          <select value={language} onChange={(e) => setLanguage(e.target.value)} required>
            <option value="">Select Language</option>
            {languages.map((lang) => (
              <option key={lang.id} value={lang.language_name}>
                {lang.language_name}
              </option>
            ))}
          </select>
        </div>
        <button type="submit">Add Movie</button>
      </form>
    </div>
  );
};

export default AddMovie;