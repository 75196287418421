//  const domain = "http://localhost/projectwork/Vidify-main/netflix/"
const domain = "http://shahanaz.atozasindia.in/Vidify/netflix/"

const api = {
    mainFolder: domain,
    registerApi: domain + "api/register.php",
    loginApi: domain + "api/login.php",
    getAllSeriesApi: domain + "api/getAllSeries.php",
    getAllMovieApi: domain + "api/getAllMovies.php",
    addVideoApi: domain + "api/addVideo.php",
    addEntityApi: domain + "api/addEntity.php",
    addMovieApi: domain + "api/AddMovie.php",
    getCategoriesApi:domain +"api/getCategories.php",
    getVideoByEntityApi:  domain+"/api/getVideosByEntity.php?entityId=",
    getLanguagesApi:domain +"/api/getLanguages.php"
}

export default api