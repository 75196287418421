import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Register from './components/Register';
import Login from './components/Login';
import Manage from './components/Manage';
import Entities from './components/entities'; // Ensure this matches the file name exactly
import EntityDetails from './components/EntityDetails'; // Import the new component
import AddVideo from './components/AddVideo'; // Import the AddVideo component
import AddMovie from './components/AddMovie'; // Import the AddMovie component
import MoviePlayer from './components/MoviePlayer'; // Import the MoviePlayer component
import './App.css';

const App = () => {
  return (
    <Router>
      <div>
        <nav>
          <ul>
            <li>
              <Link to="/">SIGN UP</Link>
            </li>
            <li>
              <Link to="/login">LOG IN</Link>
            </li>
          </ul>
        </nav>
        <Routes>
          <Route path="/" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/manage" element={<Manage />} />
          <Route path="/entities" element={<Entities />} />
          <Route path="/entity/:entityId" element={<EntityDetails />} />
          <Route path="/MoviePlayer/:entityId" element={<MoviePlayer />} />
          <Route path="/entity/:entityId/add-video" element={<AddVideo />} />
          <Route path="/addmovie" element={<AddMovie />} />
          <Route path="*" element={<div>404 Not Found</div>} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
