import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import '../App.css'; // Import the CSS file
import api from './api';

const AddVideo = () => {
  const { entityId } = useParams();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [filePath, setFilePath] = useState(null);
  const [releaseDate, setReleaseDate] = useState('');
  const [season, setSeason] = useState('');
  const [episode, setEpisode] = useState('');
  const [duration, setDuration] = useState(''); // State for duration

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFilePath(file);

    // Calculate the duration using HTML5 video element
    const video = document.createElement('video');
    video.preload = 'metadata';

    video.onloadedmetadata = () => {
      window.URL.revokeObjectURL(video.src);
      const videoDuration = Math.floor(video.duration);
      const hours = Math.floor(videoDuration / 3600);
      const minutes = Math.floor((videoDuration % 3600) / 60);
      const seconds = videoDuration % 60;
      setDuration(`${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`);
    };

    video.src = URL.createObjectURL(file);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
  
    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    formData.append('filePath', filePath);
    formData.append('releaseDate', releaseDate);
    formData.append('season', season);
    formData.append('episode', episode);
    formData.append('duration', duration);
    formData.append('entityId', entityId);
  
    fetch(api.addVideoApi, {
      method: 'POST',
      body: formData
    })
      .then(response => response.json())
      .then(data => {
        console.log('Server response:', data);
        if (data.status === 'success') {
          alert('Video added successfully');
          // Clear form fields
          setTitle('');
          setDescription('');
          setFilePath(null);
          setReleaseDate('');
          setSeason('');
          setEpisode('');
          setDuration('');
        } else {
          alert('Failed to add video: ' + data.message);
        }
      })
      .catch(error => {
        console.error('Error adding video:', error);
        alert('Error adding video: ' + error.message);
      });
  };
  

  return (
    <div className="container">
      <h2>Add New Episode And Season</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Title</label>
          <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} required />
        </div>
        <div>
          <label>Description</label>
          <textarea value={description} onChange={(e) => setDescription(e.target.value)} required />
        </div>
        <div>
          <label>Video</label>
          <input type="file" onChange={handleFileChange} required />
        </div>
        <div>
          <label>Release Date</label>
          <input type="date" value={releaseDate} onChange={(e) => setReleaseDate(e.target.value)} required />
        </div>
        <div>
          <label>Season</label>
          <input type="number" value={season} onChange={(e) => setSeason(e.target.value)} required />
        </div>
        <div>
          <label>Episode</label>
          <input type="number" value={episode} onChange={(e) => setEpisode(e.target.value)} required />
        </div>
        <input type="hidden" name="duration" value={duration} /> {/* Hidden input for duration */}
        <button type="submit">Add Video</button>
      </form>
    </div>
  );
};

export default AddVideo;
